import React, { useState, useEffect, useCallback } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { get } from 'lodash';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Loading from '../../components/Loading';
import Image from '../../components/CustomImage';
import axios from '../../services/axios';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useNotification } from '../../components/Notification/NotificationContext';
import Forms from '../../components/Forms';
import ErrorProcessor from '../../components/ErrorProcessor';

// Defina o URL base da API para concatenar o caminho da foto
const API_BASE_URL = 'http://186.250.247.230:3001'; // Ajuste conforme necessário

export default function User() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const notification = useNotification();
  const navigate = useNavigate();
  const params = useParams();
  const id = get(params, 'id', '');
  const [isLoading, setIsLoading] = useState(false);
  const [photo, setPhoto] = useState('');
  const [formValues, setFormValues] = useState({
    name: '',
    lastname: '',
    email: '',
    ramal: '',
    password: '',
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Nome deve conter no mínimo 3 caracteres.')
      .required('Nome obrigatório.'),
    ramal: Yup.string()
      .min(3, 'Ramal deve conter no mínimo 3 caracteres.')
      .required('Nome obrigatório.'),
    lastname: Yup.string()
      .min(3, 'Sobrenome deve conter no mínimo 3 caracteres.')
      .required('Sobrenome obrigatório.'),
    email: !id
      ? Yup.string().required('E-mail obrigatório.').email('E-mail inválido.')
      : Yup.string(),
    password: !id
      ? Yup.string()
          .min(6, 'Senha deve conter no mínimo 6 caracteres.')
          .required('Senha obrigatória.')
      : Yup.string(),
    confirmPassword: !id
      ? Yup.string()
          .oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais.')
          .required('Confirme sua senha.')
      : Yup.string(),
  });

  const handleFieldChange = useCallback((fieldName, value) => {
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
  }, []);

  const fields = [
    {
      name: 'name',
      id: 'name',
      label: 'Nome',
      type: 'text',
      onChange: (e) => handleFieldChange('name', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
    {
      name: 'lastname',
      id: 'lastname',
      label: 'Sobrenome',
      type: 'text',
      onChange: (e) => handleFieldChange('lastname', e.target.value),
      colSize: 4,
      additionalProps: {},
    },
  ];

  if (!id) {
    fields.push({
      name: 'email',
      id: 'email',
      label: 'E-mail',
      type: 'email',
      groupText: '@',
      onChange: (e) => handleFieldChange('email', e.target.value),
      colSize: 4,
      additionalProps: {},
    });
    fields.push({
      name: 'ramal',
      id: 'ramal',
      label: 'Ramal',
      type: 'text',
      onChange: (e) => handleFieldChange('ramal', e.target.value),
      colSize: 4,
      additionalProps: {},
    });

    fields.push({
      name: 'password',
      id: 'password',
      label: 'Senha',
      type: 'password',
      onChange: (e) => handleFieldChange('password', e.target.value),
      colSize: 4,
      additionalProps: {},
    });
    fields.push({
      name: 'confirmPassword',
      id: 'confirmPassword',
      label: 'Confirme a senha',
      type: 'password',
      onChange: (e) => handleFieldChange('confirmPassword', e.target.value),
      colSize: 4,
      additionalProps: {},
    });
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      let action = 'cadastrado';

      if (id) {
        await axios.put(`/profiles/${id}`, formValues);
        action = 'atualizado';
      } else {
        const getUser = await axios.post(`/users/`, formValues);
        const createProfile = { ...formValues, user_id: getUser.data.id };
        await axios.post(`/profiles/`, createProfile);
      }
      notification(`Usuário ${action} com sucesso`, 'success');
      navigate('/users');
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!id) return;
    async function getData() {
      try {
        setIsLoading(true);
        const { data } = await axios.get(`users/${id}`);

        // Concatene o caminho da API com o caminho relativo da foto
        const photoPath = get(data, 'UserProfile.photo', '');
        const photoUrl = photoPath ? `${API_BASE_URL}${photoPath}` : '';
        setPhoto(photoUrl);

        setFormValues({
          ...data,
          name: data.UserProfile.name,
          lastname: data.UserProfile.lastname,
          ramal: data.UserProfile.ramal,
        });
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
        setDataLoaded(true);
      }
    }

    getData();
  }, [id, notification, navigate]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs
        title={id ? 'Atualizar' : 'Cadastrar'}
        pages={[{ link: '/users', name: 'Usuários' }]}
      />
      <section>
        <Row>
          {id && (
            <Col lg={4}>
              <Card className="card-profile mb-4">
                <Card.Header
                  className="overflow-hidden"
                  style={{
                    backgroundImage: `url('/img/photos/greenShapes.jpg')`,
                  }}
                />
                <Card.Body className="text-center">
                  <div
                    className="card-profile-img mx-auto d-flex align-center"
                    style={{ maxHeight: '8rem' }}
                  >
                    <div className="position-relative overflow-hidden rounded-circle">
                      <Link
                        to={`/profile/${id}`}
                        title="Clique para alterar a foto"
                      >
                        <Image
                          src={photo || '/img/user-profile-circle.png'}
                          layout="fixed"
                          width={122}
                          height={122}
                          priority
                          alt={formValues.name}
                        />
                      </Link>
                    </div>
                  </div>

                  <h3 className="mb-3">{`${formValues.name} ${formValues.lastname}`}</h3>
                  <p className="mb-4">{formValues.email}</p>
                </Card.Body>
              </Card>
            </Col>
          )}
          <Col lg={id ? 8 : 12}>
            <Card className="mb-5">
              <Card.Header>
                <h4 className="card-heading">
                  {id ? 'Atualizar usuário' : 'Cadastrar usuário'}
                </h4>
              </Card.Header>
              <Card.Body>
                <ErrorProcessor error={apiErrors} />
                {!id || dataLoaded ? (
                  <Forms
                    initialValues={formValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    fields={fields}
                    btnSubmit={{
                      variant: 'primary',
                      type: 'submit',
                      text: id ? 'Atualizar' : 'Cadastrar',
                    }}
                  />
                ) : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </Container>
  );
}
