/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { Container, Card, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone'; // Importa o moment-timezone
import 'moment/locale/pt-br';
// Importa o locale de português do moment
moment.locale('pt-br'); // Define o locale como pt-br
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Breadcrumbs';
import DataTable from '../../components/DataTable';
import ButtonModal from '../../components/ModalConfirmation/ButtonModal';
import { useNotification } from '../../components/Notification/NotificationContext';
import ErrorProcessor from '../../components/ErrorProcessor';
import CardHeaderMore from '../../components/Cards/CardHeaderMore';

export default function Tickets() {
  const routeName = '/tickets';
  const titleName = 'Tickets';
  const navigate = useNavigate();
  const [apiErrors, setApiErrors] = useState({});
  const notification = useNotification();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const userData = useSelector((state) => state.auth.user);

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Nº Ticket',
        accessor: 'ticketNumber',
      },
      {
        Header: 'Título',
        accessor: 'title',
      },
      {
        Header: 'Solicitante',
        accessor: 'requester',
      },
      {
        Header: 'Prioridade',
        accessor: 'priority',
      },
      {
        Header: 'Responsável',
        accessor: 'assignedTo',
      },
      {
        Header: 'Criado em',
        accessor: 'createdAt',
      },
      {
        Header: 'Venc Atendimento',
        accessor: 'vencAtendimento',
      },
      {
        Header: 'Venc Solução',
        accessor: 'vencSolucao',
      },
      {
        Header: 'Estágio',
        accessor: 'status',
      },
    ],
    []
  );

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const [
        ticketsResponse,
        usersResponse,
        requestersResponse,
        userDepartmentsResponse,
        prioritiesResponse,
      ] = await Promise.all([
        axios.get(`${routeName}/`),
        axios.get(`/users`),
        axios.get(`/customers`),
        axios.get(`/userdpto`),
        axios.get(`/priorities`),
      ]);

      const userDepartments = userDepartmentsResponse.data.filter(
        (userDepartment) => userDepartment.userId === userData.id
      );
      const userDepartmentIds = userDepartments.map(
        (userDepartment) => userDepartment.departmentId
      );

      const usersMap = usersResponse.data.reduce((acc, user) => {
        acc[user.id] = user.UserProfile
          ? user.UserProfile.name
          : 'Nome não disponível';
        return acc;
      }, {});

      const requestersMap = requestersResponse.data.reduce(
        (acc, razaoSocial) => {
          const firstName = razaoSocial.razaoSocial.split(' ')[0]; // Pega o primeiro nome
          const secondName = razaoSocial.razaoSocial.split(' ')[1]; // Pega o segundo nome
          acc[razaoSocial.id] = `${firstName} ${secondName}`; // Atribui o primeiro nome ao objeto
          return acc;
        },
        {}
      );

      const prioritiesMap = prioritiesResponse.data.reduce((acc, priority) => {
        acc[priority.id] = priority.name;
        return acc;
      }, {});

      const formattedData = await Promise.all(
        ticketsResponse.data
          .filter((ticket) => {
            const inUserDepartment = userDepartmentIds.includes(
              ticket.department
            );

            const shouldShow =
              userData.isadmin === 1 ? true : ticket.status !== 'fechado';

            return inUserDepartment && shouldShow;
          })
          .map(async (ticket) => {
            const serviceDeskResponse = await axios.get(
              `/servicedesks/${ticket.serviceDesk}`
            );
            const serviceDesk = serviceDeskResponse.data;

            const priority = serviceDesk.priorities.find(
              (p) => p.id === ticket.priority
            );
            const atendimentoVencimento = priority
              ? priority.atendimentoVencimento
              : null;
            const solucaoVencimento = priority
              ? priority.solucaoVencimento
              : null;

            // Lógica para calcular o vencimento do atendimento
            let vencAtendimento = 'Não iniciado';
            if (atendimentoVencimento) {
              const createdTime = moment(ticket.createdAt).tz(
                'America/Sao_Paulo'
              ); // Converte para GMT-3 (horário de Brasília)
              const deadline = createdTime
                .clone()
                .add(moment.duration(atendimentoVencimento));
              const currentTime = moment().tz('America/Sao_Paulo'); // Mantém o fuso horário do Brasil

              if (ticket.startTime) {
                const startTime = moment(ticket.startTime).tz(
                  'America/Sao_Paulo'
                );
                const timeDiff = startTime.diff(createdTime, 'hours', true);

                vencAtendimento = currentTime.isBefore(deadline)
                  ? `✔️ SLA cumprido (${timeDiff.toFixed(0)}h)`
                  : `❌ SLA vencido (${timeDiff.toFixed(0)}h)`;
              } else {
                const remainingTime = deadline.fromNow(true);
                vencAtendimento = currentTime.isBefore(deadline)
                  ? `⏳ Vence em ${remainingTime}`
                  : `❌ Atrasado há ${remainingTime}`;
              }
            }

            // Lógica para calcular o vencimento da solução
            let vencSolucao = 'Não iniciado';
            if (ticket.startTime && solucaoVencimento) {
              const startTime = moment(ticket.startTime).tz(
                'America/Sao_Paulo'
              );
              const solutionDeadline = startTime
                .clone()
                .add(moment.duration(solucaoVencimento));
              const currentTime = moment().tz('America/Sao_Paulo');

              if (ticket.closingTime) {
                const closingTime = moment(ticket.closingTime).tz(
                  'America/Sao_Paulo'
                );
                const timeToSolve = closingTime.diff(startTime, 'hours', true);
                vencSolucao = closingTime.isBefore(solutionDeadline)
                  ? `✔️ SLA cumprido (${timeToSolve.toFixed(0)}h)`
                  : `❌ SLA vencido (${timeToSolve.toFixed(0)}h)`;
              } else {
                const remainingTime = solutionDeadline.fromNow(true);
                vencSolucao = currentTime.isBefore(solutionDeadline)
                  ? `⏳ Vence em ${remainingTime}`
                  : `❌ Atrasado há ${remainingTime}`;
              }
            }

            return {
              ...ticket,
              id: ticket.id,
              ticketNumber: ticket.id,
              title: ticket.title,
              requester: requestersMap[ticket.company],
              priority: prioritiesMap[ticket.priority] || ticket.priority,
              assignedTo: usersMap[ticket.assignedTo],
              status: ticket.status,
              createdAt: moment(ticket.createdAt)
                .tz('America/Sao_Paulo') // Exibindo no fuso horário correto
                .format('DD/MM/YYYY HH:mm:ss'),
              vencAtendimento,
              vencSolucao,
            };
          })
      );

      setData(formattedData);
      setDataLoaded(true);
    } catch (err) {
      setApiErrors(err);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [userData.id, userData.isadmin]);

  useEffect(() => {
    getData();
  }, [getData]);

  const confirmDelete = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        await axios.delete(`${routeName}/${id}`);
        notification('Ticket removido com sucesso', 'success');
        const updated = data.filter((ticket) => ticket.id !== id);
        setData(updated);
      } catch (err) {
        setApiErrors(err);
      } finally {
        setIsLoading(false);
      }
    },
    [data, notification]
  );

  const tableData = useMemo(() => {
    return data.map((ticketObj) => {
      const linksAction = [
        {
          onclick: () => {
            navigate(`${routeName}/${ticketObj.id}/edit`);
          },
          faicon: 'faEdit',
          text: 'Detalhes',
        },
      ];

      if (userData.isadmin === 1) {
        linksAction.push({
          custom: (
            <ButtonModal
              onConfirm={() => confirmDelete(ticketObj.id)}
              message={`Confirma remoção do Ticket ${ticketObj.id}?`}
              buttonProps={{ text: 'Remover', faicon: 'faTrash' }}
              textModal={{
                title: 'Remover Ticket',
                textConfirm: 'Remover',
              }}
              dropdown
            />
          ),
        });
      }

      const closedName =
        ticketObj.status === 'aberto' ? (
          <b className="text-success">Aberto</b>
        ) : ticketObj.status === 'em_atendimento' ? (
          <b className="text-warning">Em Atendimento</b>
        ) : ticketObj.status === 'pausado' ? (
          <b className="text-primary">Em Pausa</b>
        ) : (
          <b className="text-danger">Fechado</b>
        );

      return {
        ...ticketObj,
        status: (
          <div className="d-flex justify-content-between">
            <span>{closedName}</span>
            <CardHeaderMore links={linksAction} className="" />
          </div>
        ),
      };
    });
  }, [data, confirmDelete, userData]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <Loading isLoading={isLoading} />
      <Breadcrumbs title={titleName} />
      <div className="page-header d-flex justify-content-between align-items-center">
        <h1 className="page-heading">{titleName}</h1>
        <div>
          <Link to={`${routeName}/new`}>
            <Button variant="primary" className="text-uppercase">
              <FaPlus /> Novo Ticket
            </Button>
          </Link>
        </div>
      </div>
      <section>
        {dataLoaded && (
          <Card className="card-table mb-5">
            <ErrorProcessor error={apiErrors} />
            <DataTable
              items={tableData}
              columns={tableColumns}
              defaultPageSize={5}
              defaultSort="createdAt"
            />
          </Card>
        )}
      </section>
    </Container>
  );
}
