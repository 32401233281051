import React from 'react';
import { Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// import Search from './Search';
// import Notifications from './Notifications';
// import Messages from './Messages';
import UserMenu from './UserMenu';

export default function Header({ setSidebarShrink, sidebarShrink }) {
  return (
    <header className="header">
      <Navbar
        bg="white"
        expand="lg"
        variant={false}
        className="px-4 py-2 shadow"
      >
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <a
          id="sidebarToggle"
          className="sidebar-toggler text-gray-500 me-4 me-lg-5 lead"
          href="#"
          onClick={() => setSidebarShrink(!sidebarShrink)}
          aria-label="Toggle Sidebar"
          role="button"
        >
          <FontAwesomeIcon icon={faAlignLeft} />
        </a>
        <Link to="/">
          <Navbar.Brand className="fw-bold text-uppercase text-base">
            <span className="d-none d-brand-partial">Portal</span>{' '}
            <span className="d-none d-sm-inline">Service Desk</span>
          </Navbar.Brand>
        </Link>
        <div className="ms-auto d-flex align-items-center mb-0">
          {/* <Search /> */}
          {/* <Notifications /> */}
          {/* <Messages /> */}
          <UserMenu />
        </div>
      </Navbar>
    </header>
  );
}

Header.propTypes = {
  setSidebarShrink: PropTypes.func.isRequired,
  sidebarShrink: PropTypes.bool.isRequired,
};
