/* eslint-disable no-plusplus */
import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { v4 } from 'uuid';
import moment from 'moment';

import Stats1 from '../../components/Dashboards/Stats1';
import axios from '../../services/axios';
import Breadcrumbs from '../../components/Breadcrumbs';
import PageHeader from '../../components/PageHeader';
import Loading from '../../components/Loading';
import ErrorProcessor from '../../components/ErrorProcessor';
import Activity from '../../components/Activity';

export default function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [leads, setLeads] = useState({});
  const [slaStats, setSlaStats] = useState({
    paused: 0,
    attended: 0,
    overdueTodayAtt: 0, // SLA de atendimento atrasado hoje
    overdueMonthAtt: 0, // SLA de atendimento atrasado no mês
    overdueTodaySol: 0, // SLA de solução atrasado hoje
    overdueMonthSol: 0, // SLA de solução atrasado no mês
    ticketsInProgress: 0, // Tickets em atendimento
  });

  const getData = useCallback(async (loading = true) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const [statsResponse, last30TicketsResponse, userDepartmentsResponse] =
        await Promise.all([
          axios.get('/stats'),
          axios.get('/tickets/last30'),
          axios.get('/userdpto'), // Obtém os dados de usuários e departamentos
        ]);

      const statsData = statsResponse.data;
      const last30Tickets = last30TicketsResponse.data;
      const userDepartments = userDepartmentsResponse.data;

      // Mapear os usuários e departamentos
      const userMap = userDepartments.reduce((acc, userDept) => {
        acc[userDept.userId] =
          `${userDept.UserProfile.name} ${userDept.UserProfile.lastname}`;
        return acc;
      }, {});

      const departmentMap = userDepartments.reduce((acc, userDept) => {
        acc[userDept.departmentId] = userDept.Department.name;
        return acc;
      }, {});

      let pausedCount = 0;
      let attendedCount = 0;
      let overdueTodayAtt = 0; // SLA de atendimento atrasado hoje
      let overdueMonthAtt = 0; // SLA de atendimento atrasado no mês
      let overdueTodaySol = 0; // SLA de solução atrasado hoje
      let overdueMonthSol = 0; // SLA de solução atrasado no mês
      let ticketsInProgress = 0; // Tickets em atendimento

      // Calcular a quantidade de tickets com SLA pausado, atendido, atrasado e em atendimento
      await Promise.all(
        last30Tickets.map(async (ticket) => {
          const serviceDeskResponse = await axios.get(
            `/servicedesks/${ticket.serviceDesk}`
          );
          const serviceDesk = serviceDeskResponse.data;
          const priority = serviceDesk.priorities.find(
            (p) => p.id === ticket.priority
          );
          const atendimentoVencimento = priority
            ? priority.atendimentoVencimento
            : null;
          const solucaoVencimento = priority
            ? priority.solucaoVencimento
            : null;

          const currentTime = moment();
          const createdTime = moment(ticket.createdAt);

          if (ticket.status === 'pausado') {
            pausedCount++;
          }

          // Tickets em atendimento
          if (ticket.status === 'em_atendimento') {
            ticketsInProgress++;
          }

          // SLA de Atendimento
          if (!ticket.startTime && atendimentoVencimento) {
            const atendimentoDeadline = createdTime
              .clone()
              .add(moment.duration(atendimentoVencimento));

            if (currentTime.isAfter(atendimentoDeadline)) {
              if (currentTime.isSame(createdTime, 'day')) {
                overdueTodayAtt++; // SLA de atendimento atrasado hoje
              }
              overdueMonthAtt++; // SLA de atendimento atrasado no mês
            }
          }

          // SLA de Solução
          if (ticket.startTime && solucaoVencimento) {
            const startTime = moment(ticket.startTime);
            const solutionDeadline = startTime
              .clone()
              .add(moment.duration(solucaoVencimento));

            if (currentTime.isAfter(solutionDeadline)) {
              if (currentTime.isSame(startTime, 'day')) {
                overdueTodaySol++; // SLA de solução atrasado hoje
              }
              overdueMonthSol++; // SLA de solução atrasado no mês
            }

            // Se a solução foi completada a tempo
            if (ticket.closingTime && currentTime.isBefore(solutionDeadline)) {
              attendedCount++; // SLA de solução atendido
            }
          }
        })
      );

      setSlaStats({
        paused: pausedCount,
        attended: attendedCount,
        overdueTodayAtt, // SLA de atendimento atrasado hoje
        overdueMonthAtt, // SLA de atendimento atrasado no mês
        overdueTodaySol, // SLA de solução atrasado hoje
        overdueMonthSol, // SLA de solução atrasado no mês
        ticketsInProgress, // Tickets em atendimento
      });

      // Formatar os tickets para incluir nomes de usuários e departamentos
      const formattedTickets = last30Tickets.map((ticket) => ({
        ...ticket,
        assignedTo: userMap[ticket.assignedTo] || 'Usuário Desconhecido',
        departmentName:
          departmentMap[ticket.department] || 'Departamento Desconhecido',
      }));

      // Formatar leadsByDepartment com nomes de departamentos
      const leadsByDepartment = statsData.ticketsByDepartment.map((dept) => ({
        ...dept,
        departmentName:
          departmentMap[dept.department] || 'Departamento Desconhecido',
      }));

      // Formatar leadsByUser com nomes de usuários
      const leadsByUser = statsData.ticketsByUser.map((user) => ({
        ...user,
        userName: userMap[user.assignedTo] || 'Usuário Desconhecido',
      }));

      setLeads({
        todayLeads: statsData.ticketsOpenedToday,
        weekLeads: statsData.ticketsOpenedThisWeek,
        monthLeads: statsData.ticketsOpenedThisMonth,
        leadsClosedToday: statsData.ticketsClosedToday,
        leadsClosedThisWeek: statsData.ticketsClosedThisWeek,
        leadsClosedThisMonth: statsData.ticketsClosedThisMonth,
        leadsByDepartment, // Usar os nomes dos departamentos mapeados
        leadsByUser, // Usar os nomes dos usuários mapeados
        last30Tickets: formattedTickets, // Armazena os últimos 30 tickets formatados
        ticketsInProgress: statsData.ticketsInProgress, // Tickets em atendimento
        ticketsPaused: statsData.ticketsPaused, // Tickets pausados
        totalTicketsClosedThisMonth: statsData.totalTicketsClosedThisMonth, // Tickets fechados no mês
      });
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
      setDataLoaded(true);
    }
  }, []);

  useEffect(() => {
    getData();
    const intervalId = setInterval(() => {
      getData(false);
    }, 60000);

    return () => clearInterval(intervalId);
  }, [getData]);

  return (
    <Container fluid className="px-lg-4 px-xl-5">
      <ErrorProcessor error={apiErrors} />
      <Loading isLoading={isLoading} />
      <Breadcrumbs title="Dashboards" />
      <PageHeader title="Dashboards" />
      <section className="mb-3 mb-lg-5">
        {dataLoaded && !apiErrors && (
          <>
            <Row className="mb-2">
              {/* Exibe tickets abertos hoje */}
              <Col sm={4} lg={4} key={v4()} className="mb-4">
                <Stats1
                  color="green"
                  icon="sales-performance-up-1"
                  number={leads.todayLeads.toLocaleString('pt-BR') || '0'}
                  name="Tickets Abertos Hoje"
                  footer={`Fechados: ${leads.leadsClosedToday.toLocaleString('pt-BR') || '0'}`}
                />
              </Col>
              {/* Tickets Abertos na Semana */}
              <Col sm={4} lg={4} key={v4()} className="mb-4">
                <Stats1
                  color="green"
                  icon="sales-performance-up-1"
                  number={leads.weekLeads.toLocaleString('pt-BR') || '0'}
                  name="Tickets Abertos na Semana"
                  footer={`Fechados: ${leads.leadsClosedThisWeek.toLocaleString('pt-BR') || '0'}`}
                />
              </Col>
              {/* Tickets Abertos no Mês */}
              <Col sm={4} lg={4} key={v4()} className="mb-4">
                <Stats1
                  color="green"
                  icon="sales-performance-up-1"
                  number={leads.monthLeads.toLocaleString('pt-BR') || '0'}
                  name="Tickets Abertos no Mês"
                  footer={`Fechados: ${leads.leadsClosedThisMonth.toLocaleString('pt-BR') || '0'}`}
                />
              </Col>

              {/* Exibe tickets em atendimento */}
              <Col sm={4} lg={4} key={v4()} className="mb-4">
                <Stats1
                  color="blue"
                  icon="view-1"
                  number={
                    slaStats.ticketsInProgress.toLocaleString('pt-BR') || '0'
                  }
                  name="Tickets em Atendimento"
                />
              </Col>

              {/* SLA de Atendimento Atrasado Hoje */}
              <Col sm={4} lg={4} key={v4()} className="mb-4">
                <Stats1
                  color="red"
                  icon="close-1"
                  number={
                    (
                      slaStats.overdueTodayAtt + slaStats.overdueTodaySol
                    ).toLocaleString('pt-BR') || '0'
                  }
                  name="SLA Atrasado Hoje"
                  footer={`Sla atrasado mês: ${slaStats.overdueMonthAtt + slaStats.overdueMonthSol.toLocaleString('pt-BR') || '0'}`}
                />
              </Col>

              {/* SLA de Atendimento Atrasado no Mês
              <Col sm={4} lg={4} key={v4()} className="mb-4">
                <Stats1
                  color="red"
                  icon="close-1"
                  number={
                    (
                      slaStats.overdueMonthAtt + slaStats.overdueMonthSol
                    ).toLocaleString('pt-BR') || '0'
                  }
                  name="SLA Atrasado Mês"
                />
              </Col> */}

              {/* SLA de Atendimento Atendido
              <Col sm={4} lg={4} key={v4()} className="mb-4">
                <Stats1
                  color="green"
                  icon="checkmark-1"
                  number={slaStats.attended.toLocaleString('pt-BR') || '0'}
                  name="SLA Atendido"
                />
              </Col> */}

              {/* SLA Pausado */}
              <Col sm={4} lg={4} key={v4()} className="mb-4">
                <Stats1
                  color="orange"
                  icon="time-1"
                  number={slaStats.paused.toLocaleString('pt-BR') || '0'}
                  name="SLA Pausado"
                />
              </Col>
              {/* Tickets Fechados no Mês */}
              {/* <Col sm={4} lg={4} key={v4()} className="mb-4">
                <Stats1
                  color="red"
                  icon="close-1"
                  number={
                    leads.totalTicketsClosedThisMonth.toLocaleString('pt-BR') ||
                    '0'
                  }
                  name="Tickets Fechados no Mês"
                />
              </Col> */}
            </Row>
            <Row>
              <Col lg={6} className="mb-4">
                <Card className="h-100">
                  <Card.Header>
                    <h4 className="card-heading">Tickets por Departamento</h4>
                  </Card.Header>
                  <Card.Body>
                    <Row className="mb-5">
                      <Col
                        xs={6}
                        sm="auto"
                        className="flex-sm-grow-1 border-start py-3"
                      >
                        <h3 className="subtitle text-gray-500">Total</h3>
                        <div className="h1 text-primary">
                          {leads.leadsByDepartment
                            .reduce((total, data) => total + data.count, 0)
                            .toLocaleString('pt-BR') || '0'}
                        </div>
                      </Col>
                      {leads.leadsByDepartment.map((department) => (
                        <Col
                          key={v4()}
                          xs={6}
                          sm="auto"
                          className="flex-sm-grow-1 border-start py-3 d-flex align-items-center"
                        >
                          <div>
                            <h3 className="subtitle text-gray-500 fw-normal">
                              {department.departmentName}
                            </h3>
                            <div className="h4 fw-normal text-dark">
                              {department.count.toLocaleString('pt-BR')}
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6} className="mb-4">
                <Card className="h-100">
                  <Card.Header>
                    <h4 className="card-heading">Últimos 30 Tickets Criados</h4>
                  </Card.Header>
                  <Card.Body>
                    <Activity
                      title=""
                      data={leads.last30Tickets.map((ticket) => ({
                        name: `${ticket.title} - ${ticket.departmentName}`,
                        time: moment.utc(ticket.createdAt).format('DD/MM/YYYY'),
                        text: `Ticket #${ticket.id} - Responsável: ${ticket.assignedTo}`,
                        link: `/tickets/${ticket.id}/edit`,
                      }))}
                      appendHeader={
                        <>
                          <Col
                            sm={12}
                            className="border-bottom d-flex justify-content-center align-items-center"
                          >
                            <div className="h6 text-gray-500 me-3">Total </div>
                            <div className="h2 text-primary">
                              {leads.last30Tickets.length || 0}
                            </div>
                          </Col>
                          <Col sm={12} className="mt-3">
                            <h6 className="subtitle text-gray-500">
                              Tickets Criados Recentemente
                            </h6>
                          </Col>
                        </>
                      }
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="mb-4">
                <Card className="h-100">
                  <Card.Header>
                    <h4 className="card-heading">Tickets por Usuário</h4>
                  </Card.Header>
                  <Card.Body>
                    <Row className="mb-5">
                      {leads.leadsByUser.map((user) => (
                        <Col
                          key={v4()}
                          xs={6}
                          sm="auto"
                          className="flex-sm-grow-1 border-start py-3 d-flex align-items-center"
                        >
                          <div>
                            <h3 className="subtitle text-gray-500 fw-normal">
                              {user.userName}
                            </h3>
                            <div className="h4 fw-normal text-dark">
                              {user.count.toLocaleString('pt-BR')}
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </section>
    </Container>
  );
}
