/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';
import moment from 'moment';
import axios from '../../services/axios';
import Breadcrumbs from '../../components/Breadcrumbs';
import PageHeader from '../../components/PageHeader';
import Loading from '../../components/Loading';
import ErrorProcessor from '../../components/ErrorProcessor';
import DataTable from '../../components/DataTable';
import { exportToExcel } from '../../utils/exportUtils';

export default function Relatorios() {
  const [isLoading, setIsLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState(null);
  const [data, setData] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [serviceDesks, setServiceDesks] = useState([]);
  const [filterType, setFilterType] = useState('atendentes'); // Default filter type
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD'),
  });

  const [reportType] = useState('tickets'); // Can be tickets | atendidos

  // Função para buscar dados auxiliares (Perfis e Service Desks)
  const fetchAuxiliaryData = useCallback(async () => {
    try {
      const [profilesResponse, serviceDesksResponse] = await Promise.all([
        axios.get('/profiles'),
        axios.get('/servicedesks'),
      ]);
      setProfiles(profilesResponse.data);
      setServiceDesks(serviceDesksResponse.data);
    } catch (err) {
      setApiErrors(err);
    }
  }, []);

  // Função para controlar a alteração das datas
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateRange((prev) => ({ ...prev, [name]: value }));
  };

  // Função para formatar status dos tickets
  const formatStatus = (status) => {
    switch (status) {
      case 'aberto':
        return 'Aberto';
      case 'fechado':
        return 'Fechado';
      case 'em_atendimento':
        return 'Em Atendimento';
      case 'pausado':
        return 'Pausado';
      default:
        return status;
    }
  };

  // Função para calcular o SLA (Atendimento ou Solução)
  const calculateSLA = (ticket, slaType) => {
    const { createdAt, startTime, closingTime } = ticket;
    const serviceDesk = serviceDesks.find(
      (desk) => desk.id === ticket.serviceDesk
    );
    const priority = serviceDesk.priorities.find(
      (p) => p.id === ticket.priority
    );
    const { atendimentoVencimento } = priority;
    const { solucaoVencimento } = priority;

    let slaAtraso = 'N/A';

    if (slaType === 'sla_atendimento') {
      // SLA de Atendimento
      const currentTime = moment();
      const createdTime = moment(createdAt);
      const deadline = createdTime
        .clone()
        .add(moment.duration(atendimentoVencimento));

      if (!startTime) {
        slaAtraso = currentTime.isAfter(deadline)
          ? `❌ Atrasado há ${deadline.fromNow(true)}`
          : `⏳ Vence em ${deadline.fromNow(true)}`;
      } else {
        const startMoment = moment(startTime);
        slaAtraso = startMoment.isAfter(deadline)
          ? `❌ SLA vencido (${startMoment.diff(deadline, 'hours', true).toFixed(2)}h)`
          : `✔️ SLA cumprido`;
      }
    } else if (slaType === 'sla_solucao') {
      // SLA de Solução
      if (!startTime) {
        slaAtraso = 'Não iniciado';
      } else {
        const startMoment = moment(startTime);
        const solutionDeadline = startMoment
          .clone()
          .add(moment.duration(solucaoVencimento));

        if (!closingTime) {
          slaAtraso = moment().isAfter(solutionDeadline)
            ? `❌ Atrasado há ${solutionDeadline.fromNow(true)}`
            : `⏳ Vence em ${solutionDeadline.fromNow(true)}`;
        } else {
          const closingMoment = moment(closingTime);
          const solutionDuration = closingMoment.diff(
            startMoment,
            'hours',
            true
          );
          slaAtraso =
            solutionDuration > moment.duration(solucaoVencimento).asHours()
              ? `❌ SLA vencido (${solutionDuration.toFixed(2)}h)`
              : `✔️ SLA cumprido (${solutionDuration.toFixed(2)}h)`;
        }
      }
    }

    return slaAtraso;
  };

  // Função para obter dados do relatório
  const getReportData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`/relatorios`, {
        params: {
          filterType,
          reportType,
          startDate: dateRange.start,
          endDate: dateRange.end,
        },
      });

      const mappedData = response.data
        .filter((ticket) => {
          // Adicionando a lógica para filtrar tickets fechados quando o filtro "encerrado" for selecionado
          if (filterType === 'encerrado') {
            return ticket.status === 'fechado'; // Apenas tickets com status "fechado"
          }
          return true;
        })
        .map((ticket) => {
          const assignedToName = profiles.find(
            (profile) => profile.user_id === ticket.assignedTo
          );
          const serviceDesk = serviceDesks.find(
            (desk) => desk.id === ticket.serviceDesk
          );
          const department = serviceDesk.departments.find(
            (dep) => dep.id === ticket.department
          );
          const stage = serviceDesk.stages.find(
            (stg) => stg.id === ticket.stageid
          );

          return {
            ...ticket,
            assignedTo: assignedToName
              ? `${assignedToName.name} ${assignedToName.lastname}`
              : 'Desconhecido',
            departmentName: department ? department.name : 'Desconhecido',
            serviceDeskName: serviceDesk ? serviceDesk.name : 'Desconhecido',
            stageName: stage ? stage.name : 'Desconhecido',
            formattedStatus: formatStatus(ticket.status),
            createdAt: moment(ticket.createdAt).format('DD/MM/YYYY HH:mm:ss'),
            startTime: ticket.startTime
              ? moment(ticket.startTime).format('DD/MM/YYYY HH:mm:ss')
              : 'Não iniciado',
            closingTime: ticket.closingTime
              ? moment(ticket.closingTime).format('DD/MM/YYYY HH:mm:ss')
              : 'Ainda aberto',
            sla: calculateSLA(ticket, filterType),
          };
        });

      // Agrupamento de dados conforme o tipo de filtro selecionado
      let groupedData;
      switch (filterType) {
        case 'atendentes':
          groupedData = Object.values(groupBy(mappedData, 'assignedTo'));
          break;
        case 'departamentos':
          groupedData = Object.values(groupBy(mappedData, 'departmentName'));
          break;
        case 'servicedesk':
          groupedData = Object.values(groupBy(mappedData, 'serviceDeskName'));
          break;
        case 'stageid':
          groupedData = Object.values(groupBy(mappedData, 'stageName'));
          break;
        case 'status':
          groupedData = Object.values(groupBy(mappedData, 'formattedStatus'));
          break;
        default:
          groupedData = mappedData;
      }

      const totalTickets = mappedData.length;
      groupedData = groupedData.map((group) => ({
        ...group,
        percentual: `${((group.total / totalTickets) * 100).toFixed(2)}%`,
      }));

      setData(groupedData);
    } catch (err) {
      setApiErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [filterType, reportType, dateRange, profiles, serviceDesks]);

  // Função auxiliar para agrupar dados
  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      const groupKey = currentValue[key] || 'Desconhecido';
      if (!result[groupKey]) {
        result[groupKey] = { key: groupKey, total: 0, tickets: [] };
      }
      result[groupKey].tickets.push(currentValue);
      result[groupKey].total += 1;
      return result;
    }, {});
  };

  useEffect(() => {
    fetchAuxiliaryData();
  }, [fetchAuxiliaryData]);

  useEffect(() => {
    getReportData();
  }, [getReportData]);

  const handleExport = () => {
    exportToExcel(data, 'Relatorio_Tickets');
  };

  return (
    <Container fluid>
      <Breadcrumbs title="Relatórios" />
      <PageHeader title="Carga de Trabalho" />
      <Loading isLoading={isLoading} />
      <ErrorProcessor error={apiErrors} />

      <Row>
        <Col lg={12} className="mb-4">
          <Card>
            <Card.Body>
              <Form>
                <Row>
                  <Col md={3}>
                    <Form.Group controlId="startDate">
                      <Form.Label>Data de início</Form.Label>
                      <Form.Control
                        type="date"
                        name="start"
                        value={dateRange.start}
                        onChange={handleDateChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="endDate">
                      <Form.Label>Data de fim</Form.Label>
                      <Form.Control
                        type="date"
                        name="end"
                        value={dateRange.end}
                        onChange={handleDateChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group controlId="filterType">
                      <Form.Label>Filtrar por</Form.Label>
                      <Form.Control
                        as="select"
                        value={filterType}
                        onChange={(e) => setFilterType(e.target.value)}
                      >
                        <option value="atendentes">Atendentes</option>
                        <option value="departamentos">Departamentos</option>
                        <option value="servicedesk">Mesa de Serviço</option>
                        <option value="status">Status</option>
                        <option value="stageid">Estágios</option>
                        <option value="sla_atendimento">
                          SLA Atrasado (Atendimento)
                        </option>
                        <option value="sla_solucao">
                          SLA Atrasado (Solução)
                        </option>
                        <option value="encerrado">Tickets Encerrados</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3} className="d-flex align-items-end">
                    <Button variant="primary" onClick={getReportData}>
                      Exibir
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={12} className="mb-4">
          <Card className="card-table">
            <Card.Body>
              <Row className="mb-4">
                <Col md={9}>
                  <h4>{`Total: ${data.length} tickets`}</h4>
                </Col>
                <Col md={3} className="text-right">
                  <Button variant="success" onClick={handleExport}>
                    <FaDownload /> Download XLSX
                  </Button>
                </Col>
              </Row>
              <DataTable items={data} columns={generateColumns(filterType)} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

function generateColumns(filterType) {
  if (filterType === 'sla_atendimento') {
    return [
      { Header: 'Ticket', accessor: 'id' },
      { Header: 'Data Criação', accessor: 'createdAt' },
      { Header: 'Data de Início', accessor: 'startTime' },
      { Header: 'SLA Atraso', accessor: 'sla' },
    ];
  }
  if (filterType === 'sla_solucao') {
    return [
      { Header: 'Ticket', accessor: 'id' },
      { Header: 'Início do atendimento', accessor: 'startTime' },
      { Header: 'Solução do atendimento', accessor: 'closingTime' },
      { Header: 'SLA Atraso', accessor: 'sla' },
    ];
  }
  if (filterType === 'encerrado') {
    // Colunas para o filtro "Tickets Encerrados"
    return [
      { Header: 'Ticket', accessor: 'id' },
      { Header: 'Data Criação', accessor: 'createdAt' },
      { Header: 'Data de Encerramento', accessor: 'closingTime' },
      {
        Header: 'Ações',
        accessor: 'actions',
        Cell: ({ row }) => (
          <Button
            variant="primary"
            onClick={() =>
              (window.location.href = `/tickets/${row.original.id}/edit`)
            }
          >
            Ver
          </Button>
        ),
      },
    ];
  }
  // Outras colunas padrão para os filtros já existentes
  switch (filterType) {
    case 'atendentes':
      return [
        { Header: 'Atendente', accessor: 'key' },
        { Header: 'Tickets', accessor: 'total' },
        { Header: 'Percentual', accessor: 'percentual' },
      ];
    case 'departamentos':
      return [
        { Header: 'Departamento', accessor: 'key' },
        { Header: 'Tickets', accessor: 'total' },
        { Header: 'Percentual', accessor: 'percentual' },
      ];
    case 'servicedesk':
      return [
        { Header: 'Mesa de Serviço', accessor: 'key' },
        { Header: 'Tickets', accessor: 'total' },
        { Header: 'Percentual', accessor: 'percentual' },
      ];
    case 'stageid':
      return [
        { Header: 'Estágio', accessor: 'key' },
        { Header: 'Tickets', accessor: 'total' },
        { Header: 'Percentual', accessor: 'percentual' },
      ];
    case 'status':
      return [
        { Header: 'Status', accessor: 'key' },
        { Header: 'Tickets', accessor: 'total' },
        { Header: 'Percentual', accessor: 'percentual' },
      ];
    default:
      return [];
  }
}
